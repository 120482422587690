@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');



html,
body {
    margin: 0;
    font-family: 'Rubik', sans-serif; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(47deg, #66b3e9, #8a40ff); */
    background-color: #f5f5f5;
}

#root{
  height: 100%;
  width: 100%;
}




