.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.text{
    color: #fff;
    font-size: 24px;
}